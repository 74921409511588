import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  LargeHeader,
  MediumHeader,
  SmallHeader,
  Subheader,
  Paragraph,
  TextSection,
  Spacer,
} from "../components/BasicText/BasicTextElements"

const NotFoundPage = () => (
  <Layout>
    <TextSection>
      <LargeHeader>404: Not Found</LargeHeader>
      <Paragraph>
        This page could not be found. Try checking in a different universe.
      </Paragraph>
    </TextSection>
  </Layout>
)

export const Head = () => <SEO title="404: Not Found" />

export default NotFoundPage
